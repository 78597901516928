(function () {
const pagetop = document.querySelector('.js-pagetop');

pagetop.addEventListener('click', scrollTop);

function scrollTop() {
  window.scroll({ top: 0, behavior: 'smooth' });
}

// スクロールされたら表示
window.addEventListener('scroll', scrollEvent);
function scrollEvent() {
  if (window.pageYOffset > 100) {
    pagetop.classList.add('is-show');
  } else if (window.pageYOffset < 100) {
    pagetop.classList.remove('is-show');
  }
}
}());