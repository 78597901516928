(function () {
  if (location.pathname == '/about/') {

    const leaderArray = document.querySelectorAll('.js-leader')
    leaderArray.forEach(function(leader){
      leader.addEventListener('click', detailOpen)
    });

    const close = document.querySelector('.js-details-close')
    close.addEventListener('click', detailClose)

    const details = document.querySelector('.js-details')
    const detail = document.querySelectorAll('.js-detail')

    const prevArray = document.querySelectorAll('.js-details-prev')
    const nextArray = document.querySelectorAll('.js-details-next')

    prevArray.forEach(function(prev){
      prev.addEventListener('click', prevDetail)
    })
    nextArray.forEach(function(next){
      next.addEventListener('click', nextDetail)
    })

    let num
    let target

    function detailOpen(e){
      num = [].slice.call(leaderArray).indexOf(e.currentTarget)
      target = detail[num]
      details.classList.add('is-show')
      target.classList.add('is-show')
      // スクロール禁止
      posY = window.pageYOffset
      document.body.style.top = -window.pageYOffset + 'px'
      document.body.classList.add('is-fixed')
    }

    function detailClose() {
      details.classList.remove('is-show')
      target.classList.remove('is-show')
      // menu.classList.remove('is-open')
      // menu.removeEventListener('click', menuClose)
      // menu.addEventListener('click', menuOpen)
      // スクロール禁止解除
      document.body.classList.remove('is-fixed')
      document.body.style.top = ''
      window.scrollTo({
        top: posY,
        behavior: 'auto'
      })
    }

    function prevDetail() {
      num--
      if(num == -1) num = detail.length - 1
      target.classList.remove('is-show')
      target = detail[num]
      target.classList.add('is-show')
      details.scrollTop = 0
    }

    function nextDetail() {
      num++
      if(num == detail.length) num = 0
      target.classList.remove('is-show')
      target = detail[num]
      target.classList.add('is-show')
      details.scrollTop = 0
    }
  }
}());