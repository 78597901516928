function handleResize () {
  const width = window.screen.width || window.innerWidth 
  const viewport = document.querySelector('[name="viewport"]')
  if (viewport) {
    const content = viewport.getAttribute('content')
    let viewportContent = content
    if (width < 375) {
      viewportContent = 'width=375'
    } else {
      viewportContent = 'width=device-width, initial-scale=1.0'
    }
    if (content !== viewportContent) {
      viewport.setAttribute('content', viewportContent);
    }
  }
}

window.addEventListener('resize', handleResize)
handleResize()