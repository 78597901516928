(function () {
  const button = document.querySelector('.js-header-button')
  button.addEventListener('click', navOpen)
  const nav = document.querySelector('.js-header-nav')

  function navOpen(){
    button.classList.add('is-open')
    button.removeEventListener('click', navOpen)
    button.addEventListener('click', navClose)

    nav.classList.add('is-show')
  }

  function navClose() {
    button.classList.remove('is-open')
    button.removeEventListener('click', navClose)
    button.addEventListener('click', navOpen)

    nav.classList.remove('is-show')
  }
}());