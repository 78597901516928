(function () {
  if (location.pathname == '/') {
    new Swiper('.js-index-banner', {
      effect: 'fade',
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      fadeEffect: { 
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
    });
    
    let swiperCase;
    const breakPointCase = 768;
    let isPC = window.innerWidth <= breakPointCase;
    window.addEventListener('resize', checkCase, false);
    checkCase();

    function checkCase() {
      if (window.innerWidth > breakPointCase && !isPC) {
        swiperCase = new Swiper('.js-index-case', {
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          navigation: {
            nextEl: ".js-case-next",
            prevEl: ".js-case-prev",
          }
        });
        isPC = true;
      } else if (window.innerWidth <= breakPointCase && isPC) {
        if(swiperCase)swiperCase.destroy(false, true);
        isPC = false;
      }
    }

    let swiperLogo;
    const breakPointLogo = 1024;
    let isCol03 = window.innerWidth <= breakPointLogo;
    window.addEventListener('resize', checkLogo, false);
    checkLogo();

    function checkLogo() {
      if (window.innerWidth > breakPointLogo && !isCol03) {
        if(swiperLogo)swiperLogo.destroy(false, true);
        swiperLogo = new Swiper('.js-index-logo-col3', {
          slidesPerView: 1,
          speed: 1000,
          centeredSlides: true,
          loop: true,
          
          navigation: {
            nextEl: ".js-logo-col3-next",
            prevEl: ".js-logo-col3-prev",
          }
        });
        isCol03 = true;
      } else if (window.innerWidth <= breakPointLogo && isCol03) {
        if(swiperLogo)swiperLogo.destroy(false, true);
        swiperLogo = new Swiper('.js-index-logo-col1', {
          slidesPerView: 1,
          speed: 500,
          centeredSlides: true,
          loop: true,
          navigation: {
            nextEl: ".js-logo-col1-next",
            prevEl: ".js-logo-col1-prev",
          }
        });
        isCol03 = false;
      }
    }

    const movieOpen = document.querySelector('.js-index-movie-open')
    const movie = document.querySelector('.js-index-movie')
    const movieClose = document.querySelector('.js-index-movi-close')
    movieOpen.addEventListener('click', openMovie)
    movieClose.addEventListener('click', closeMovie)
    function openMovie() {
      movie.classList.add('is-show')
    }
    function closeMovie() {
      movie.classList.remove('is-show')
    }

    const pagination = document.querySelector('.swiper-pagination')
    const bg = pagination.dataset.bg.split(',')
    const bullets = pagination.querySelectorAll('.swiper-pagination-bullet')
    console.log(bg)
    console.log(bullets)
    for (let i = 0; i < bullets.length; i += 1) {
      const bullet = bullets[i]
      bullet.style.backgroundImage = 'url(' + bg[i] + ')'
    }
  }
}());