(function () {
  if (location.pathname == '/contact/') {
    const radioContact = document.querySelector('.js-radio-inquiry')
    const contact = document.querySelector('.js-inquiry')
    const radioRequest = document.querySelector('.js-radio-request')
    const request = document.querySelector('.js-request')
    
    radioContact.addEventListener('change', function() {
      if(radioContact.checked) {
        contact.classList.add('is-show')
        request.classList.remove('is-show')
      } else {
        request.classList.add('is-show')
        contact.classList.remove('is-show')
      }
    })

    radioRequest.addEventListener('change', function() {
      if(radioRequest.checked) {
        request.classList.add('is-show')
        contact.classList.remove('is-show')
      } else {
        contact.classList.add('is-show')
        request.classList.remove('is-show')
      }
    })
  }
}());